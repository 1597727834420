$primary-background: #d9eff4;
$danger-background: #fbe5df;

.body {
  padding: 0;
  overflow: hidden;
}

.name {
  display: inline-block;
  min-height: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 90%;
  margin: 2px 0;
  font-size: 12px;
  line-height: 16px;

  &__content {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 3px 8px 3px 8px;
    text-align: center;
    transition: all 0.25s;
    font-weight: 400;
    color: var(--primary-color);
    background-color: var(--white-color);
    border: 1px solid var(--primary-background-color);

    .name_isCollocation & {
      padding-left: 0;
    }

    &_excluded {
      color: var(--danger-color);
      border: 1px solid $danger-background;
      background-color: $danger-background;
    }

    &_known {
      background-color: var(--primary-background-color);
      color: var(--primary-color);
      border: 1px solid transparent;

      &.name__content_excluded {
        background-color: $danger-background;
        color: var(--danger-color);
      }
    }

    &_error {
      border: 1px solid var(--danger-color);
    }
  }
}

.actions {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  flex-shrink: 0;
  gap: 4px;
  margin-left: 4px;
  position: relative;
  top: 2px;

  .name_isCollocation & {
    margin-left: 0;
  }

  .name_focused:hover & {
    display: inline-flex;
  }

  &__icon {
    font-size: 12px;
    cursor: pointer;

    &_small {
      font-size: 12px;
    }

    &_active {
      color: var(--primary-color-dark);

      .name__content_excluded & {
        color: var(--danger-color);
      }
    }

    &_ban {
      font-size: 11px;
    }
  }

  &__icon-container {
    line-height: 1;
    font-size: 12px;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;

    & > svg {
      width: 100%;
    }

    &_location {
      width: 8px;
      height: 11px;
    }

    &_locationRange {
      width: auto;

      & > svg {
        width: 12px;
      }
    }
  }
}

.term {
  position: relative;

  &:before,
  &:after {
    display: inline-block;
    content: '"';
    position: absolute;
    background-color: var(--white-color);
    color: var(--white-color);
    transition: all 0.25s;

    .name__content_known & {
      background-color: var(--primary-background-color);
      color: var(--primary-background-color);
    }

    .name__content_excluded & {
      background-color: $danger-background;
      color: $danger-background;
    }
  }

  &:after {
    right: 0;
  }
}

.location-range {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 12px;

  &-wrapper {
    letter-spacing: -1px;
    height: 12px;
    font-size: 12px;
    line-height: 16px;
    top: -2px;

    &:before {
      top: unset;
      right: unset;
    }
  }

  &-body {
    margin-top: 25px;
    margin-left: 0;
    z-index: 2080;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 150px;
  }

  &-option {
    font-family: 'Proxima Nova', sans-serif;
    color: var(--grey-color-darker);
    padding: 0;
    display: flex;
    align-items: center;

    &:hover {
      background: transparent;
    }
  }

  &-success {
    color: var(--primary-color);
    margin-left: auto;
  }
}
