.rules {
  font-size: 12px;
  padding: 8px 16px;
}
.rule {
  color: var(--danger-color-light);

  &_valid {
    color: var(--secondary-color);
  }
}
