:global {
  #bold-tooltip,
  #size-tooltip,
  #bulletedList-tooltip,
  #numberedList-tooltip,
  #italic-tooltip,
  #formatting-tooltip,
  #variables-tooltip,
  #insert-link-tooltip,
  #link-edit,
  #link-delete,
  #save-template-tooltip,
  #condition-tooltip {
    z-index: 9999;
  }

  .RichEditor-align-right div {
    text-align: right;
  }

  .RichEditor-align-center div {
    text-align: center;
  }

  .RichEditor-align-left div {
    text-align: left;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: initial;
  overflow: hidden;
  flex: 1;

  &_mod_subject {
    overflow: initial;
  }
}

.style-bar {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--white-color);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  &_mod_subject {
    visibility: hidden;
    position: absolute;
    bottom: -25px;
    left: -3px;
    transform: translateY(100%);
    z-index: 99;
  }

  &_visible {
    visibility: visible;
  }

  button.button {
    color: var(--grey-color-darker);
  }

  &-content {
    display: flex;
  }

  &_transparent {
    box-shadow: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
  }
}

.editor {
  line-height: 1.4;
  flex: 1;

  &_mod_subject,
  &_mod_signature {
    overflow-x: auto;
  }

  &_mod_editor {
    overflow: auto;
  }

  ol,
  ul {
    margin: 1em 0;
    padding-left: 40px;
  }
}
