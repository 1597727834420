:root {
  --menu-item-height: 74px;
}

.icon {
  font-size: 20px;
  padding-bottom: 8px;
  padding-top: 16px;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--menu-item-height);
  height: 100%;
  background: var(--grey-color-lightest);
  text-align: center;
  font-size: 11px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  a,
  a:link,
  a:visited {
    color: inherit;
  }

  .dropdown-item {
    color: var(--grey-color);
    font-size: 12px;
    border-radius: 4px;
    height: 30px;
    padding: 7px 10px 5px 10px;

    &:hover {
      background: var(--primary-background-color);
    }
  }

  .container-top {
    margin: auto;
    width: 100%;

    .item {
      height: var(--menu-item-height);
      width: 100%;
      background: var(--grey-color-lightest);
      color: var(--grey-color);
      line-height: 14px;
      position: relative;

      &:hover {
        color: var(--primary-color);
      }

      .badge {
        position: absolute;
        top: 1px;
        right: -10px;
      }
    }

    .item_inactive {
      display: block;
    }

    .item_active {
      height: var(--menu-item-height);
      width: 100%;
      display: block;
      background: var(--primary-background-color);
      color: var(--grey-color);

      &:hover {
        color: var(--grey-color);
      }
    }
  }

  .container-bottom {
    width: 100%;
    padding: 20px 0;
    margin: auto;

    a,
    a:link,
    a:visited {
      color: inherit;
    }

    .icon {
      display: block;
    }

    .item {
      text-align: center;
      color: var(--grey-color);
      height: var(--menu-item-height);

      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

div.logo {
  justify-content: center;
  height: var(--menu-item-height);
}

div.logo_active {
  margin: 0;
  background: var(--primary-background-color);
}

.item-dropdown {
  &_opened {
    background: var(--grey-color-lightest);
  }
  &_active {
    background: var(--primary-background-color);
  }
}

.dropdown-button {
  width: 40px;
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -18px;
    display: block;
    width: 74px;
    height: 74px;
  }

  &_opened {
    &:hover {
      border-radius: 6px;
      color: var(--grey-color);

      &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: -5px;
        width: 52px;
        height: 56px;
        z-index: -1;
        border-radius: 6px;
        background: var(--primary-background-color);
      }
    }
  }

  &-title {
    display: flex;
    justify-content: center;
  }
}

.hr {
  border-bottom: 1px solid var(--grey-color);
  margin-bottom: 8px;
  margin-top: 8px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &_horizontal {
    flex-direction: row;
    gap: 8px;
  }
}

.badge {
  margin-left: 10px;

  &_old {
    background-color: var(--grey-color);
  }
}

.badge-old {
  background-color: var(--grey-color);
}
