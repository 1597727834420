$background-color: #d5ebf1;
$yellow-background-color: #fcf3de;

.variable {
  background-color: rgba(30, 160, 200, 0.25);
  color: var(--grey-color-darker);
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 1px;
  border-radius: 5px;
}

.container {
  display: inline-block;
  border-radius: 5px;
  padding: 2px 4px;
  margin: 2px 0;
  position: relative;
  color: var(--primary-color);
  background-color: $background-color;

  &_empty {
    border: 1px dashed var(--warning-color);
    color: var(--warning-color);
    background-color: $yellow-background-color;
    padding: 1px 3px;
    cursor: pointer;
  }
}

.actions {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: $background-color;
  padding: 2px 4px;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;

  .container:hover & {
    display: inline-flex;
  }

  &__icon {
    font-size: 12px;
    cursor: pointer;
  }

  &__text {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-right: 2px;
  }
}

.editing {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  padding: 1px 4px;
  margin: 2px 0;
  color: var(--black-color);
  background-color: var(--white-color);
  border: 1px solid var(--grey-color);

  .input {
    width: 120px;
    font-size: inherit;
    border: none;
    height: initial;
    padding: 0;
  }

  &__icon {
    font-size: 12px;
    cursor: pointer;

    &_color_blue {
      color: var(--primary-color);
      margin: 0 8px;
    }

    &_color_grey {
      color: var(--grey-color-darkest);
    }
  }
}
