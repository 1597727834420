@import 'components/Base/Colors/Colors';

:root {
  --primary-color: #{$primary-color};
  --primary-color-dark: #{$primary-color-dark};
  --primary-color-darker: #{$primary-color-darker};
  --primary-color-light: #{$primary-color-light};
  --primary-background-color: #{$primary-background-color};
  --primary-color--rgb: #{red($primary-color)}, #{green($primary-color)}, #{blue($primary-color)};

  --secondary-color: #{$secondary-color};
  --secondary-color-dark: #{$secondary-color-dark};
  --secondary-color-darker: #{$secondary-color-darker};
  --secondary-color-darkest: #{$secondary-color-darkest};
  --secondary-color-light: #{$secondary-color-light};
  --secondary-color-lighter: #{$secondary-color-lighter};
  --secondary-color-lightest: #{$secondary-color-lightest};
  --secondary-color-transparent: #{$secondary-color-transparent};

  --success-color: #{$success-color};
  --success-color-dark: #{$success-color-dark};
  --success-color-darker: #{$success-color-darker};

  --danger-color: #{$danger-color};
  --danger-color-dark: #{$danger-color-dark};
  --danger-color-darker: #{$danger-color-darker};
  --danger-color-light: #{$danger-color-light};
  --danger-color--rgb: #{red($danger-color)}, #{green($danger-color)}, #{blue($danger-color)};

  --warning-color: #{$warning-color};
  --warning-color-dark: #{$warning-color-dark};
  --warning-color-darker: #{$warning-color-darker};

  --white-color: #{$white-color};
  --grey-color-lightest: #{$grey-color-lightest};
  --grey-color-lighter: #{$grey-color-lighter};
  --grey-color-light: #{$grey-color-light};
  --grey-color: #{$grey-color};
  --grey-color-dark: #{$grey-color-dark};
  --grey-color-darker: #{$grey-color-darker};
  --grey-color-darkest: #{$grey-color-darkest};
  --black-color: #{$black-color};

  --orange-color: #{$orange-color};
  --orange-color-dark: #{$orange-color-dark};
  --orange-color-darker: #{$orange-color-darker};

  --badge-color: #{$badge-color};

  --default-filter-shadow: #{$default-filter-shadow};
}

:global {
  .matchy {
    --primary-color: #{$primary-color-matchy};
    --primary-color-dark: #{$primary-color-dark-matchy};
    --primary-color-darker: #{$primary-color-darker-matchy};
    --primary-color-darkest: #{$primary-color-darkest-matchy};
    --primary-color-light: #{$primary-color-light-matchy};
    --primary-color-lighter: #{$primary-color-lighter-matchy};
    --primary-color-lightest: #{$primary-color-lightest-matchy};
    --primary-color-transparent: #{$primary-color-transparent-matchy};
    --primary-background-color: #{$primary-background-color-matchy};
    --primary-color--rgb: 76, 69, 158;

    --badge-color: #{$badge-color-matchy};
  }

  #app {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    background: var(--white-color);
  }

  .ah-loading {
    cursor: progress !important;

    & * {
      pointer-events: none !important;
    }
  }

  .popover,
  .tooltip {
    font-family: 'Proxima Nova', sans-serif;
  }

  .tooltip {
    font-size: 12px;
  }
}

.container {
  width: 100%;
  height: 100%;
}

:global {
  .modal-dialog {
    &_messages {
      padding: 0 10px;
      width: 1000px;
      max-width: 1000px;
      margin: 0 auto;

      .modal-content {
        box-shadow: none;
        filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.25));
        border-radius: 5px 5px 0 0;
        border: 0;
        background: initial;
      }

      .modal-body {
        padding: 0;
      }
    }

    &_app {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90vh;

      .modal-content {
        box-shadow: none;
        border: 0;
      }

      .modal-body {
        padding: 0;
      }
    }

    &_metaqueries {
      max-width: 880px;
      align-items: flex-start;
    }

    &_folders {
      max-width: 1000px;
      align-items: flex-start;
    }
  }

  .modal-backdrop_app {
    z-index: 1060;

    ~ .modal {
      z-index: 1070;
    }
  }
}
