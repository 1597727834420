@import 'components/Base/Fonts/Fonts';

:global {
  #event_message_tooltip {
    z-index: 9999;
  }
}
.event {
  color: var(--grey-color-darker);
  font-size: $s;
  margin-bottom: $xs;
  display: flex;
  .icon {
    min-width: 16px;
    margin-right: 4px;
  }
}

.activity-icon {
  color: var(--grey-color-darkest);
}

.activity-text {
  background: var(--grey-color-lightest);
  color: var(--grey-color-darkest);
  padding: 4px 8px;
  border-radius: 16px;
}
