.container {
  position: relative;

  button.button {
    color: var(--grey-color-darker);
  }

  .list {
    transform: translateY(-100%) translateX(10px);
    top: 0;
    border-radius: 5px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
    border: none;
    background-color: var(--white-color);
    padding: 10px;
  }

  .field {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    input {
      width: 200px;
      padding: 2px 5px;
      outline: none;
      border: none;
      border-bottom: 1px solid var(--grey-color-lightest);
    }

    &-name {
      white-space: nowrap;
      margin-right: 10px;
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button:last-child {
      margin-left: 5px;
    }
  }

  .data {
    display: flex;

    &-link {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 2px;
      white-space: nowrap;
    }

    &-icon {
      margin-left: 10px;
      cursor: pointer;
      color: var(--grey-color-dark);

      &:hover {
        color: var(--grey-color-darker);
      }
    }
  }
}

.link {
  text-decoration: underline;
  color: var(--primary-color);
}

a.link {
  text-decoration: underline;

  &:hover,
  &:visited {
    text-decoration: underline;
  }
}

.input-container {
  display: block;
}
