:root {
  --height-header: 60px;
  --margin-left: 90px;
}

.wrapper {
  position: relative;
  display: none;
  z-index: 12;
}
.wrapper_show {
  display: block;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--white-color);
  width: 100%;
  min-height: 100%;
}

.header {
  height: auto;
  border-bottom: 1px solid #c0c6cc;
  background: var(--white-color);
}

.scrollbars {
  margin: 0;
  padding: 0;
}

.heading {
  height: var(--height-header);
  margin: 0 0 0 var(--margin-left);
  line-height: var(--height-header);
  font-size: 22px;
  color: var(--black-color);
}

.close {
  position: absolute;
  top: 5px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  transition: background 0.1s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.1);

    .close__icon {
      color: rgba(0, 0, 0, 1);
    }
  }

  &__icon {
    position: absolute;
    top: 15px;
    right: 0;
    left: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.1s ease-in-out;
  }

  &__tip {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.content {
  padding: 30px 0 100px 0;
}
