:root {
  --button-size: 74px;
}

.side-menu-dropdown {
  border: 0;
  filter: none;
  line-height: 14px;
  position: relative;
  height: var(--button-size);

  .button {
    color: var(--grey-color);
    border-style: none;
    outline: none;
    width: var(--button-size);
    height: var(--button-size);
    display: flex;
    justify-content: center;

    &_active {
      background: var(--primary-background-color);
    }
    &_opened {
      color: var(--primary-color);
    }
  }

  &_opened {
    filter: drop-shadow(0 0 2px var(--grey-color-light));
    position: relative;
    z-index: 1;

    .button {
      background: var(--white-color);
      border-radius: 10px 0 0 10px;
    }
  }

  .custom-dropdown-body {
    top: 0;
    left: 55px;
    border-radius: 0 10px 10px 10px;
    margin-top: 0;
    box-shadow: none;

    &_inverted {
      transform: translateY(calc(-100% + var(--button-size)));
      border-radius: 10px 10px 10px 0;
    }
  }
}

.content {
  padding: 8px;
  position: relative;

  &_align_left {
    text-align: left;
  }

  .items {
    white-space: nowrap;
    display: inline-block;
  }

  .corner {
    width: 18px;
    height: 18px;
    background: radial-gradient(circle at bottom left, transparent 70%, white 71%);
    position: absolute;
    top: calc(var(--button-size) - 8px);
    left: -26px;

    &_hidden {
      visibility: hidden;
    }

    &_inverted {
      top: initial;
      bottom: calc(var(--button-size) - 8px);
      background: radial-gradient(circle at top left, transparent 70%, white 71%);
    }

    &_small {
      width: 15px;
      height: 15px;
      left: -22px;
    }
  }
}

.search-button-container {
  margin-left: 6px;
  padding-left: 20px;
}

.badge {
  position: absolute;
  top: 1px;
  right: -10px;
}
