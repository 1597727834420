.header {
  padding: 5px 15px;
}
.body {
  padding: 0 5px 10px 5px;
}

.item {
  line-height: 1.2;
  margin: 0 10px;
  padding-top: 10px;
}

.header {
  display: flex;
  position: sticky;
  top: 0;
  font-weight: 600;
  background-color: var(--grey-color-lightest);
}
.angle {
  width: 10px;
  color: var(--grey-color-lighter);
  margin-left: -10px;
  display: inline-block;

  &_hidden {
    display: none;
  }
}

.variant {
  color: var(--grey-color-darker);
  font-weight: 300;
  &_hidden {
    display: none;
  }
}

.synonym_with_variants {
  display: flex;
  cursor: pointer;
}
