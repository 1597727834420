.container {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.background,
.zone {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.background {
  background: var(--white-color);
  opacity: 0.8;
  z-index: 1;
}

.zone {
  z-index: 2;
  border: 4px dashed var(--grey-color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: var(--grey-color-light);
  cursor: copy;
}
