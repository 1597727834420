$color-term-default: var(--grey-color-lightest);

.name_isKnown {
  font-weight: 600;
  background-color: $color-term-default;
  border-radius: 5px;
  padding: 5px;
}

.name {
  &_disabled {
    opacity: 0.5;
  }

  &_bold {
    font-weight: 600;
  }
}

.ellipsis {
  position: relative;
  top: 2px;
  padding: 0 4px 0 1px;
  font-size: 10px;
  color: var(--primary-color);
  cursor: pointer;
}

.location-range {
  position: relative;

  &:before {
    content: attr(data-value);
    line-height: 1.3;
    top: 2px;
    right: 3px;
    font-size: 12px;
    font-family: monospace;
    color: var(--primary-color);
    letter-spacing: -1px;
    cursor: pointer;
    position: relative;
  }
}

.body {
  padding: 0;
  overflow: hidden;
}
