.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--grey-color-darker);
  font-size: 24px;
  font-weight: 600;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--grey-color-dark);
  margin-top: 24px;
}

span.logo {
  width: 64px;
  height: 64px;
}

.button {
  display: block;
  margin-top: 24px;
}

.error-trace {
  display: none;
  white-space: pre-wrap;
  max-height: 400px;
  text-align: left;

  &_show {
    display: block;
  }
}
