:root {
  --height-group: 38px;
  --border-radius-group: 5px;
  --color-silver: var(--grey-color-light);
}

.excluded {
  background: #ffccc0;
}

.container {
  composes: form__row from './SearchForm.scss';
  position: relative;
  align-items: stretch;
  flex-wrap: nowrap;
  min-height: var(--height-group);
  border-radius: var(--border-radius-group);
  outline: none;
  justify-content: center;

  .select {
    position: absolute;
    right: 100%;
  }
}

.container_focused {
  &:not(.container_text) {
    border-color: var(--primary-color);
    box-shadow: 0 0 6px var(--primary-background-color);
  }

  z-index: 1;
}

.inner {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 27px 5px 0;

  &_full {
    padding: 0;
  }
}

.select {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0;
  background: var(--white-color);
  border-top-left-radius: var(--border-radius-group);
  border-bottom-left-radius: var(--border-radius-group);
}

.btn {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  white-space: nowrap;
  user-select: none;

  &__title {
    display: inline-block;
    margin: 0 3px 0 0;
  }

  &__caret {
    display: inline-block;
    width: 0;
    height: 0;
    padding: 0;
    border-top: 4px solid var(--grey-color);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-left: 4px solid rgba(0, 0, 0, 0);
    content: '';
  }

  &_bold {
    font-weight: 600;
    padding-right: 12px;

    .btn__title {
      margin-right: 4px;
    }

    .btn-caret {
      color: var(--grey-color);
      font-size: 14px;
    }
  }
}

.separator {
  margin: 0 12px 0 0;

  &_thin {
    margin: 0;
  }
}

.suggest {
  flex: 1 1 auto;
  height: calc(var(--height-group) - 2px);
}

.append {
  font-size: 26px;
  height: 26px;
  line-height: 26px;
  color: var(--grey-color-dark);
  cursor: pointer;
}

.remove {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -28px;
  top: 0;
  bottom: 0;
  padding: 9px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;

  .container:hover & {
    display: flex;
  }
}

.location-ranges {
  display: flex;
  align-items: center;
  cursor: pointer;

  &_disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

