:root {
  --color-gold: #ffd700;
  --color-gold-darker: #ccae00;
  --color-silver-light: #c0c0c0;
  --color-silver-darker: #8c8c8c;
  --color-bronze: #e29444;
  --color-bronze-darker: #a36527;

  --size-xsmall: 12px;
  --size-small: 14px;
  --size-medium: 18px;
  --size-large: 24px;
}

.logo_full,
.logo_short {
  width: 100%;
  height: 100%;
}

.expand {
  width: 11px;
  height: 7px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain;
}

/**
 * Sizes
 */
.xsmall {
  composes: icon;
  width: var(--size-xsmall);
  height: var(--size-xsmall);
}
.small {
  composes: icon;
  width: var(--size-small);
  height: var(--size-small);
}
.medium {
  composes: icon;
  width: var(--size-medium);
  height: var(--size-medium);
}
.large {
  composes: icon;
  width: var(--size-large);
  height: var(--size-large);
}

/**
 * Colors
 */
.gold {
  color: var(--color-gold-darker);
  fill: var(--color-gold);
}
.silver {
  color: var(--color-silver-darker);
  fill: var(--color-silver-light);
}
.bronze {
  color: var(--color-bronze-darker);
  fill: var(--color-bronze);
}
.regular {
  color: rgba(0, 0, 0, 0.6);
}
