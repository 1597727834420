.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 48px 48px 24px;
  gap: 24px;
  min-width: 600px;
}

.logo {
  width: 100px;
  height: 100px;
  background: 0 0 scroll no-repeat;
  background-size: cover;
  background-image: url('components/Logo/logo-amazinghiring-full.svg');
}

.close-btn {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 20px;
  cursor: pointer;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  margin-top: 24px;
}

.emoji {
  font-size: 100px;
  line-height: 1;
}
