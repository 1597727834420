.container {
  position: relative;

  button.button {
    color: var(--grey-color-darker);
  }

  .list {
    transform: translateY(-100%) translateX(10px);
    top: 0;

    .check {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      font-size: 12px;
    }

    .option {
      margin-left: 24px;

      &_size_small {
        font-size: x-small;
      }

      &_size_large {
        font-size: large;
      }

      &_size_huge {
        font-size: xx-large;
      }
    }

    .label {
      justify-content: flex-start;
      position: relative;
    }
  }
}
