.container {
  width: 248px;
  padding: 8px;
}

.title {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 8px;
}

.buttons {
  margin-top: 24px;
  display: flex;
  gap: 8px;
  width: 100%;

  .button {
    font-size: 13px;
    height: 32px;
  }
}

.input {
  display: flex;
  align-items: center;
}

