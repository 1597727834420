.container {
  color: var(--grey-color-darker);
  transition: background 0.3s;
  cursor: pointer;
  font-size: 14px;
  padding: 0.35em 0.7em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background: rgba(67, 68, 69, 0.045);
  }

  input {
    display: none;
  }
}
