:root {
  --height: 60px;
  --size-icon: 32px;
}

.container {
  display: flex;
  align-items: center;
  height: var(--height);
  min-width: 40px;
  max-width: 300px;
  margin: 0 10px 0 10px;

  &_jointalent {
    margin: 0;
  }
}
.container_initial {
  justify-content: flex-end;
  padding: 0 110px 0 0;
}

.icon_amazinghiring {
  width: var(--size-icon);
  height: var(--size-icon);
  background: 0 0 scroll no-repeat;
  background-size: cover;
  background-image: url('./logo-amazinghiring.svg');

  &_festive {
    width: 50px;
    height: 51px;
    margin-left: -20px;
    background: 0 0 scroll no-repeat url('./new-year_logo.svg');
    background-size: contain;

    &.icon_amazinghiring_full {
      background: 0 0 scroll no-repeat url('./logo-amazinghiring-full.svg');
    }
  }

  &_full {
    width: 186px;
    height: var(--size-icon);
    margin-right: -40px;
    background-image: url('./logo-amazinghiring-full.svg');
  }
}

.icon_matchy {
  width: 40px;
  height: 40px;
  background: 0 0 scroll no-repeat url('./logo-matchy.svg');
  background-size: cover;

  &_festive {
    width: 50px;
    height: 51px;
    margin-left: -20px;
    background: 0 0 scroll no-repeat url('./matchy_new-year_logo.svg');
    background-size: contain;

    &.icon_matchy_full {
      width: 205px;
      height: 40px;
      background: 0 0 scroll no-repeat url('./logo-matchy-full.svg');
      background-size: contain;
    }
  }

  &_full {
    width: 205px;
    height: 40px;
    background: center center scroll no-repeat url('./logo-matchy-full.svg');
    background-size: contain;
  }
}

.icon_jointalent {
  width: 74px;
  height: 74px;
  background: 0 0 scroll no-repeat url('./jointalent.png');
  background-size: cover;
}
