@import url('../../constants/globals.scss');

:root {
  --side-menu-width: 74px;
  --header-default: 330px;
  --header-leftward: var(--glob-layout-gap);
}

.container {
  width: 100%;
  min-height: 100%;
  background: var(--white-color);
  position: relative;
  display: flex;
}

.side {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
  width: var(--side-menu-width);
  min-width: 74px;
}

.top {
  position: fixed;
  top: 0;
  min-width: 168px;
  width: 100%;
  z-index: 4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background: var(--white-color);
  display: flex;
  align-items: stretch;
  left: var(--side-menu-width);

  &_full {
    left: 0;
  }
}

.content {
  width: 100%;
  min-height: 100%;
}

.header {
  margin-left: var(--header-default);
  transition: margin-left ease-in-out 0.3s;

  &_leftward {
    margin-left: var(--header-leftward);
  }

  &_flexible {
    padding-left: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}
