:root {
  --height-container: 60px;
  --color-salmon: #d78273;
}

.container {
  position: fixed;
  top: calc(-1 * var(--height-container));
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: var(--height-container);
  padding: 0 15px;
  background: var(--secondary-color);
  z-index: 1100;
  transition: top ease-in-out 0.3s, visibility ease-in 0.5s;
  visibility: hidden;
}
.container_error {
  background: var(--color-salmon);
}

.container_warning {
  background: var(--warning-color);
}

.container_visible {
  top: 0;
  visibility: visible;
}

.close {
  margin: 0 21px 0 11px;
  color: var(--white-color);
  cursor: pointer;
}

.message {
  color: var(--white-color);
  font-size: 18px;
}

.action {
  composes: message;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 50%, rgba(0, 0, 0, 0) 50%);
  background-repeat: repeat-x;
  background-position: 0 95%;
  background-size: 2px 2px;
  cursor: pointer;
}
