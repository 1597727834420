:root {
  --height-input: 38px;
  --border-radius-form: 5px;
  --color-orange-red: #e7532b;
  --color-almost-black: #555555;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

div.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 11px 0 0;
  transition: max-height 0.1s ease-in-out;

  &_compact {
    width: 820px;
    display: flex;
    justify-content: flex-end;

    div.form {
      width: 672px;
    }
  }
}
.wrapper_active {
  height: auto;
  max-height: 999px;
  overflow: visible;
}

.form {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 696px;

  &_narrow {
    width: 820px;
  }

  .wrapper_active & {
    width: 810px;

    &_narrow {
      width: 820px;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    margin: 0 0 11px;
    justify-content: space-between;

    &_aligned {
      justify-content: flex-end;
    }
  }
}

.query {
  composes: form__row;
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
  min-height: var(--height-input);
  padding: 0 0 0 35px;
  border: 1px solid var(--grey-color-light);
  border-radius: var(--border-radius-form);
  outline: none;
  z-index: 0;

  &_disabled {
    padding-left: 15px;

    .query__editor {
      cursor: default;
    }
  }

  &_compact {
    width: calc(100% - 188px);
    padding: 0 0 0 12px;
  }
}
.query__search {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 30px;
  align-items: center;
  justify-content: center;
}

.query__editor {
  height: 1.5em;
  overflow: hidden;
}

.query__clear {
  composes: query__search;
  left: auto;
  right: 2px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.7) 15%,
    rgba(255, 255, 255, 1) 100%
  );
  content: '';
  z-index: 1;
  cursor: pointer;
}

.buttons {
  position: absolute;
  right: 100%;
  display: flex;
  margin: 0 15px 0 0;
}

.new-query {
  margin: 0 10px 0 0;

  .wrapper_active & {
    display: none;
  }

  &__plus {
    display: inline-block;
    margin: 0 3px 0 0;
    font-size: 20px;
    vertical-align: middle;
  }
}

.hint {
  flex: 1;
  max-width: 450px;
  text-align: center;
  align-self: center;

  &__text {
    display: inline-flex;
    padding: 6px 8px 6px 10px;
    border-radius: 3px;
    background: rgba(255, 255, 0, 0.5);
    color: var(--color-almost-black);

    a {
      color: var(--color-almost-black);
      background-image: linear-gradient(
        to top,
        transparent,
        transparent 2px,
        var(--color-almost-black) 2px,
        var(--color-almost-black) 3px,
        transparent 3px
      );

      &:hover {
        background: none;
      }
    }

    &-close {
      height: 12px;
      margin: -1px 0 0 6px;
      cursor: pointer;
    }
  }
}

.btngroup {
  display: flex;
  align-items: center;
  width: 696px;
  justify-content: flex-end;
}

.search-btn {
  min-width: 75px;
  height: 38px;
}

.btn {
  &_space_right {
    margin: 0 8px 0 0;
  }
}

.tooltip {
  position: relative;
  z-index: 12;
  display: inline-block;
  text-align: center;
  line-height: 1.2;
  font-size: 11px;
  background: var(--grey-color-darkest);
  border-radius: 4px;
  color: var(--grey-color-lightest);
  padding: 2px 8px;
}

.arrow {
  position: absolute;
  top: -5px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  border-color: transparent;
  border-style: solid;
  border-width: 0 5px 5px;
  border-bottom-color: var(--black-color);
}

.form-type-button {
  letter-spacing: 1px;
  width: 100%;
  margin-top: 11px;

  &:hover {
    background: var(--white-color);
  }
  &_appearance_alt {
    box-shadow: 0 0 1px var(--secondary-color);

    &:hover {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }
}

.history {
  height: auto;
}

.save-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &_active {
    color: #fb8b00;
    cursor: initial;
    animation: fade 1s;
    opacity: 1;
  }

  svg {
    margin-top: -2px;
  }
}

.condition-dropdown {
  position: absolute;
  right: 100%;
  width: max-content;
}
