.list {
  overflow: auto;
  max-height: 350px;
  top: 80%;

  &_mod_editor,
  &_mod_signature {
    transform: translateY(-100%) translateX(10px);
    top: 0;
  }
}

.container {
  position: relative;
}

button.button {
  color: var(--grey-color-darker);
  padding-top: 3px;
  padding-bottom: 3.5px;
}

.var {
  font-weight: 900;
  color: var(--grey-color-darker);
}

.add-var {
  position: sticky;
  bottom: 0;
  white-space: nowrap;
  background: inherit;

  &::before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: var(--white-color);
  }
}
