.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--grey-color);
  padding: 0 15px 10px 15px;
  margin-bottom: 8px;

  &-name {
    color: var(--grey-color);
    font-size: 12px;
  }

  &-company {
    color: var(--grey-color);
    font-size: 10px;
    font-weight: 300;
  }
}

.hr {
  border-bottom: 1px solid var(--grey-color);
  margin-bottom: 8px;
  margin-top: 8px;
}
