@import 'components/Base/Fonts/Fonts';

.container {
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  border-left: 4px solid var(--primary-color);
}

.content {
  width: 100%;
  margin-left: 16px;
}

.title {
  font-size: $l;
  line-height: 1.3;
  font-weight: 600;
}

.info {
  font-size: 20px;
  color: var(--primary-color);
}

.buttons {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}
