:global {
  body.resizing {
    & * {
      user-select: none;
    }
  }
}

.container {
  display: inline-block;
  position: relative;
}

.image {
  max-width: 100%;
  border: 1px solid transparent;
  user-select: initial;

  &_focused {
    border: 1px solid var(--primary-color-light);
    user-select: none;
  }
}

.corner {
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--primary-color-light);
  border: 1px solid white;

  &_position_topLeft {
    top: 0;
    left: 0;
    cursor: nwse-resize;
  }

  &_position_topRight {
    top: 0;
    right: 0;
    cursor: nesw-resize;
  }

  &_position_bottomRight {
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
  }

  &_position_bottomLeft {
    bottom: 0;
    left: 0;
    cursor: nesw-resize;
  }
}
