.container {
  position: relative;

  button.button {
    color: var(--grey-color-darker);
  }

  .list {
    transform: translateY(-100%) translateX(10px);
    top: 0;

    .label {
      color: var(--grey-color-darker);
    }
  }
}
