.wrapper {
  position: fixed;
  min-height: 100px;
  width: 100%;
  max-width: 350px;
  top: 16px;
  right: 16px;
  z-index: 5;
}

.snackbar {
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);
  background: var(--white-color);
  width: 100%;
  overflow: hidden;
}
